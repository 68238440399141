<template>
  <div class="input-group  mb-3">
    <input type="text" class="form-control" placeholder="Type statement keywords...">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary" type="button" id="button-addon2"><fa icon="search" /> Search</button>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
